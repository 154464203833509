import { configureStore } from '@reduxjs/toolkit'

import { highlightsSlice } from "./features/highlights";
import { highlightVideoSlice } from "./features/highlight-video";


export const store = configureStore({
    reducer: {
        highlights: highlightsSlice.reducer,
        highlightVideo: highlightVideoSlice.reducer,
    }
})