import { Play, Game, VideoAsset } from '../interfaces/schemas';

export const getPeriodName = (period: number): string => {
    switch (period) {
        case 1:
            return '1st';
        case 2:
            return '2nd';
        case 3:
            return '3rd';
        case 4:
            return '4th';
        default:
            return '';
    }
};

export const stringToHTML = (str: string): HTMLElement => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
};

export const getPlaysFromHTML = (html: HTMLElement): Play[] => {
    const inner = html.querySelectorAll('#__NEXT_DATA__')[0].innerHTML;
    if (inner) {
        const data = JSON.parse(inner);
        if (data?.props?.pageProps?.playByPlay?.actions) {
            return data.props.pageProps.playByPlay.actions as Play[];
        } else {
            return [];
        }
    } else {
        return [];
    }
};

export const getGamesFromHTML = (html: HTMLElement): Game[] => {
    const inner = html.querySelector('#__NEXT_DATA__')?.innerHTML;
    if (inner) {
        const data = JSON.parse(inner);
        if (data?.props?.pageProps?.games) {
            return data.props.pageProps.games as Game[];
        } else {
            return [];
        }
    } else {
        return [];
    }
};

export const convertDateToISO = (date: Date): string =>
    date.toISOString().split('T')[0];

export const getQSPs = () => {
    let qsps = window.location.href.split('?');
    if (qsps.length && qsps[1]) {
        qsps = qsps[1].split('&');
    }
    const map = new Map();
    qsps.forEach((qsp) => {
        const keys = qsp.split('=');
        map.set(keys[0], keys[1]);
    });
    return map;
};

export const getDistinctKeys = (items: any[], property: string): string[] => {
    const keys: { [key: string]: number } = {};
    items.forEach((item) => {
        const key = item[property] as string;
        keys[key.toLowerCase().trim()] = 1;
    });
    return Object.keys(keys);
};

export const convertStringToTitleCase = (text: string): string =>
    text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

export const findDeltaBetweenPlays = (
    selectedPlays: Play[],
    newlySelectedPlays: Play[]
): Play[] => {
    const delta: Play[] = [];
    if (selectedPlays.length === 0) {
        return newlySelectedPlays;
    }
    if (newlySelectedPlays.length === 0) {
        return selectedPlays;
    }

    if (selectedPlays.length > newlySelectedPlays.length) {
        selectedPlays.forEach((play) => {
            const index = newlySelectedPlays.findIndex(
                (newPlay) => newPlay.actionId === play.actionId
            );
            if (index === -1) {
                delta.push(play);
            }
        });
    } else if (selectedPlays.length < newlySelectedPlays.length) {
        newlySelectedPlays.forEach((play) => {
            const index = selectedPlays.findIndex(
                (selectedPlay) => selectedPlay.actionId === play.actionId
            );
            if (index === -1) {
                delta.push(play);
            }
        });
    }

    return delta;
};

export const setHighlightClipsMetadata = (
    plays: Play[],
    videoMetadata: { [key: string]: VideoAsset }
): void => {
    plays
        .filter((play) => videoMetadata[play.actionNumber])
        .forEach(
            (play) =>
                (play.videoAsset = {
                    videoUrl: videoMetadata[play.actionNumber].videoUrl,
                    duration: videoMetadata[play.actionNumber].duration,
                    thumbnailUrl: videoMetadata[play.actionNumber].thumbnailUrl,
                })
        );
};

export const getTimeRemainingFromPlay = (play: Play): string => {
    return `${play.period}Q ${
        play.clock
            .replaceAll('PT', '')
            .replaceAll('S', '')
            .split('M')
            .join(':')
            .split('.')[0]
    }`;
};
