import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { TelemetryService } from '../telemetry-service';
import React from 'react';

export interface IDateCarousel {
    date: Date;
    onNavigateBackward: () => void;
    onNavigateForward: () => void;
    isFetchingGames: boolean;
}
export const DateCarousel = ({ date, onNavigateBackward, onNavigateForward, isFetchingGames }: IDateCarousel) => {
    const navigateBackward = React.useCallback(() => {
        TelemetryService.log('date_carousel_backward_selected', { date });
        onNavigateBackward();
    }, [onNavigateBackward, date]);

    const navigateForward = React.useCallback(() => {
        TelemetryService.log('date_carousel_forward_selected', { date });
        onNavigateForward();
    }, [onNavigateForward, date]);

    React.useEffect(() => {
        TelemetryService.log('date_carousel', { date });
    }, [date]);

    return (
        <Box sx={dateCarouselContainerStyle}>
            <IconButton onClick={navigateBackward} disabled={isFetchingGames}>
                <ArrowBackIcon htmlColor='black' />
            </IconButton>
            <Typography sx={dateStyle} color="text.secondary" gutterBottom>
                {date.toLocaleDateString()}
            </Typography>
            <IconButton onClick={navigateForward} disabled={isFetchingGames}>
                <ArrowForwardIcon htmlColor='black' />
            </IconButton>
        </Box>
    )
}

const dateStyle = {
    fontSize: 20,
    fontWeight: 'bolder',
    fontFamily: 'Knockout Wide,sans-serif;',
    color: 'black'
};

const dateCarouselContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: "15px 0"
};