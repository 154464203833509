import { Box } from "@mui/material"
import { TeamInGame } from "../interfaces/schemas"

export const TeamName = ({ team }: { team: TeamInGame }) => {
    return (
        <Box sx={teamNameStyle}>
            <img alt={`${team.teamName} logo`} src={`https://cdn.nba.com/logos/nba/${team.teamId}/primary/L/logo.svg`} width={20} height={20} />
            {team.teamName}
        </Box>
    )
}

const teamNameStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2px'
}