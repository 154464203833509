import React from 'react';
import { Box, Stack, Button, LinearProgress, Typography } from "@mui/material";
import { PlayCircleOutlined, OpenInNew } from '@mui/icons-material';
import { Play, VideoAsset } from "../interfaces/schemas";
import { HighlightClip } from "./highlight-clip";
import { useSelector, useDispatch } from 'react-redux'
import { selectHighlights, highlightRemoved, highlightsCleared } from "../redux/features/highlights";
import { highlightVideoAdded } from "../redux/features/highlight-video";
import { TelemetryService } from '../telemetry-service';
import { createHighlightVideo } from '../data/apiUtils';

export interface IReel {
    plays: Play[];
    onRemovePlay: (play: Play) => void;
    onSelectPlay: (play: Play) => void;
}

export const Reel = ({ plays, onRemovePlay, onSelectPlay }: IReel) => {
    const [isHighlighVideoLoading, setIsHighlightVideoLoading] = React.useState(false);
    const [highlightVideoUrl, setHighlightVideoUrl] = React.useState<string | undefined>(undefined);
    const highlights = useSelector(selectHighlights);

    const dispatch = useDispatch();

    React.useEffect(() => {
        setHighlightVideoUrl("");
    }, [highlights]);

    const onHighlightReelClick = React.useCallback(async () => {
        const videoAssets = highlights.map(highlight => highlight.videoAsset).filter(asset => asset) as VideoAsset[];
        if (videoAssets?.length) {
            TelemetryService.log('highlight_clip_create_attempt', { videoAssets: JSON.stringify(videoAssets) });
            setIsHighlightVideoLoading(true);
            const videoUrl = await createHighlightVideo(videoAssets);
            if (videoUrl) {
                setHighlightVideoUrl(videoUrl);
                dispatch(highlightVideoAdded(videoUrl));
                dispatch(highlightsCleared());
                TelemetryService.log('highlight_clip_create_success', { videoAssets: JSON.stringify(videoAssets), videoUrl });
            }
            setIsHighlightVideoLoading(false);
        }
    }, [highlights, dispatch]);

    const onPlayReel = React.useCallback(() => {
        if (highlightVideoUrl) {
            TelemetryService.log('highlight_clip_link_open_success', { videoUrl: highlightVideoUrl });
            window.open(highlightVideoUrl, '_blank');
        }
    }, [highlightVideoUrl]);

    if (!highlights?.length) {
        return null;
    }
    return (
        <Box>
            <Stack sx={highlightButtonContainerStyle} direction="column" gap={0.5}>
                {!highlightVideoUrl && <Button variant="contained" onClick={onHighlightReelClick} disabled={isHighlighVideoLoading} startIcon={<PlayCircleOutlined />}>
                    {isHighlighVideoLoading ? "Creating highlight reel..." : "Create highlight reel"}
                </Button>
                }
                {highlightVideoUrl && <Button variant="contained" onClick={onPlayReel} disabled={isHighlighVideoLoading} startIcon={<OpenInNew />}>
                    {"Play highlight reel"}
                </Button>}
                {isHighlighVideoLoading && <LinearProgress />}
                <Typography sx={clipTextStyle}>{`${highlights.length} clip${highlights.length > 1 ? "s" : ""}`}</Typography>
            </Stack>
            <Box sx={reelContainerStyle}>
                <Stack direction="row" gap={2} sx={reelListStyle}>
                    {highlights.map((highlight: Play) => <HighlightClip key={highlight.actionId} play={highlight} showRemoveButton={!isHighlighVideoLoading} />)}
                </Stack>
            </Box>
        </Box>
    );
}

const reelContainerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '170px',
    backgroundColor: '#f6f6f6',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto'
}

const clipTextStyle = {
    alignSelf: 'center'
}
const highlightButtonContainerStyle = {
    position: 'fixed',
    bottom: "165px",
    height: '70px',
    width: '100%',
    display: 'flex',
    backgroundColor: '#f6f6f6',
}

const reelListStyle = {
    display: 'flex',
    marginLeft: '10px',
    marginRight: '10px'
}