import { createSlice, current } from '@reduxjs/toolkit'

export interface HighlightVideoState {
    value: string;
}
const initialState: { value: string } = { value: "" };

export const highlightVideoSlice = createSlice({
    name: 'highlightVideo',
    initialState,
    reducers: {
        highlightVideoAdded(state: HighlightVideoState, action) {
            state.value = action.payload;
        },
    }
});

export const { highlightVideoAdded } = highlightVideoSlice.actions;

export const selectHighlightVideo = (state: any) => state.highlightVideo.value as string;