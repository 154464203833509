import { Box, Typography } from "@mui/material";

export interface IEmptyStateProps {
    text: string;
}

export const EmptyState = ({ text }: IEmptyStateProps) => {
    return (
        <Box px={emptyStateStyle}>
            <Typography variant="h6">
                {text}
            </Typography>
        </Box>
    );
}

const emptyStateStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
};