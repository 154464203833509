import * as React from 'react';
import Box from '@mui/material/Box';
import { uniqBy } from 'lodash';
import ReactGA from "react-ga";
import { useGames } from "./hooks/use-games";
import { TelemetryService } from './telemetry-service';
import { Game, Play, Player } from './interfaces/schemas';
import { getQSPs } from './helpers/utils';
import { AppBar } from './components/app-bar';
import { GameCarousel } from './components/game-carousel';
import { DateCarousel } from './components/date-carousel';
import { Loader } from './components/loader';
import { GameContainer } from './components/game-container';
import { EmptyState } from './components/empty-state';
import { getPlaysForGame } from './data/apiUtils';
import { Reel } from './components/reel';

TelemetryService.init();
ReactGA.initialize("G-PSTVY6L44X");

export default function GameHighlights() {
  const qspDate = getQSPs().get('gameDate');
  const [plays, setPlays] = React.useState([] as Play[]);
  const [selectedGame, setSelectedGame] = React.useState<Game | undefined>(undefined);
  const [players, setPlayers] = React.useState([] as Player[]);
  const [isFetchingGameStats, setIsFetchingGameStats] = React.useState(false);
  const [date, setDate] = React.useState(qspDate ? new Date(qspDate) : new Date());
  const { games, isLoading: isFetchingGames } = useGames({ date });

  const getPlayByPlay = React.useCallback(async (game: Game) => {
    const playsInGame = await getPlaysForGame(game.gameId);
    if (playsInGame) {
      let playersInGame = Array.from(new Set(playsInGame.map(play => ({ playerName: play.playerNameI, playerLastName: play.playerName, teamName: play.teamTricode }))));
      playersInGame = uniqBy(playersInGame, 'playerName').filter(player => player.playerName);
      setPlayers(playersInGame);
      setPlays(playsInGame);
    }
    setIsFetchingGameStats(false);
  }, []);

  React.useEffect(() => {
    if (!games?.length) {
      setSelectedGame(undefined);
    } else {
      setSelectedGame(games[0]);
      setIsFetchingGameStats(true);
      getPlayByPlay(games[0]);
    }
    TelemetryService.log('home_page_view', { games: JSON.stringify(games), gamesLength: games?.length, date });
  }, [games, date, getPlayByPlay]);

  React.useEffect(() => {
    ReactGA.pageview("/");
  }, []);

  const onGameSelected = React.useCallback((game: Game) => {
    setSelectedGame({ ...game });
    getPlayByPlay(game);
    setIsFetchingGameStats(true);
  }, [getPlayByPlay]);

  const onNavigateBackward = React.useCallback(() => {
    setDate(new Date(date.setDate(date.getDate() - 1)));
    setSelectedGame(undefined);
  }, [date]);

  const onNavigateForward = React.useCallback(() => {
    setDate(new Date(date.setDate(date.getDate() + 1)));
    setSelectedGame(undefined);
  }, [date]);

  return (
    <Box>
      <AppBar />
      <DateCarousel date={date} onNavigateBackward={onNavigateBackward} onNavigateForward={onNavigateForward} isFetchingGames={isFetchingGames} />
      {isFetchingGames && <Loader />}
      {!isFetchingGames && <GameCarousel games={games} onGameSelected={onGameSelected} selectedGame={selectedGame} isFetchingGameStats={isFetchingGameStats} />}
      {!isFetchingGames && selectedGame && games?.length && <GameContainer isFetchingGameStats={isFetchingGameStats} players={players} game={selectedGame} plays={plays} teams={[selectedGame.homeTeam, selectedGame.awayTeam]} />}
      {!isFetchingGames && !games?.length && <EmptyState text={"No games today"} />}
      <Reel plays={[]} onRemovePlay={() => { }} onSelectPlay={() => { }} />
    </Box>
  );
}

