import { Button, Stack } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

import React from "react";
import { TelemetryService } from "../telemetry-service";
import { selectHighlightVideo } from "../redux/features/highlight-video";
import { useSelector } from "react-redux";

export interface IVideoContainer {
    videoUrl: string | undefined;
}
export const VideoContainer = ({ videoUrl }: IVideoContainer) => {
    const [isLinkCopied, setIsLinkCopied] = React.useState(false);

    const highlightVideo = useSelector(selectHighlightVideo);
    const videoRef = React.useRef<HTMLVideoElement>() as React.MutableRefObject<HTMLVideoElement>;

    const video = highlightVideo;


    React.useEffect(() => {
        videoRef.current?.load();
    }, [video]);

    const onCopyLink = React.useCallback(() => {
        if (video) {
            navigator.clipboard.writeText(video);
            setIsLinkCopied(true);
            TelemetryService.log('highlight_link_copied', { videoUrl: video });
            setTimeout(() => setIsLinkCopied(false), 3000);
        }
    }, [video]);

    if (!video) {
        return null;
    }

    return (
        <Stack direction="column" spacing={1} sx={videoContainerStyle}>
            <video ref={videoRef} width="100%" height="100%" controls autoPlay playsInline>
                <source src={video} type="video/mp4" />
            </video>
            <Button variant={isLinkCopied ? "outlined" : "contained"} onClick={onCopyLink} startIcon={isLinkCopied ? <DoneOutlineIcon /> : <ContentCopyIcon />}>
                {isLinkCopied ? "Copied link! " : "Copy highlight link"}
            </Button>
        </Stack>
    );
}

const videoContainerStyle = {
    marginBottom: '5px'
};