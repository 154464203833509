import { createSlice, current } from '@reduxjs/toolkit'
import { Play } from '../../interfaces/schemas'

export interface HighlightsState {
    value: Play[];
}
const initialState: { value: Play[] } = { value: [] };

export const highlightsSlice = createSlice({
    name: 'highlights',
    initialState,
    reducers: {
        highlightsAdded(state: HighlightsState, action) {
            const newPlays = action.payload;
            if (newPlays?.length) {
                newPlays.forEach((play: Play) => {
                    // const curr = current(state.value);
                    const playIndex = state.value.findIndex(highlight => highlight.actionId === play.actionId);
                    if (playIndex === -1) {
                        state.value.push(play);
                    }
                })
            }
        },
        highlightRemoved(state: HighlightsState, action) {
            const removeIndex = state.value.findIndex(play => play.actionId === action.payload.actionId);
            state.value.splice(removeIndex, 1);
        },
        highlightsRemoved(state: HighlightsState, action) {
            action.payload.forEach((playToRemove: Play) => {
                const removeIndex = state.value.findIndex(play => play.actionId === playToRemove.actionId);
                state.value.splice(removeIndex, 1);
            });
        },
        highlightsCleared(state: HighlightsState) {
            state.value = [];
        },
    }
});

export const { highlightsAdded, highlightRemoved, highlightsRemoved, highlightsCleared } = highlightsSlice.actions;

export const selectHighlights = (state: any) => state.highlights.value as Play[];