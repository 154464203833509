import { CircularProgress, FormControl, InputLabel, Stack, Box, NativeSelect } from "@mui/material";
import React, { ChangeEvent } from "react";
import { convertStringToTitleCase, getDistinctKeys } from "../helpers/utils";
import { Game, Play, Player, TeamInGame } from "../interfaces/schemas";
import { EmptyState } from "./empty-state";
import { HighLights } from "./highlights";
import { TeamName } from "./team-name";

export interface IGameContainer {
    isFetchingGameStats: boolean;
    game: Game;
    plays: Play[];
    players: Player[];
    teams: TeamInGame[];
}
const DENIED_CATEGORIES = ['', 'period', 'violation', 'substitution', 'timeout', 'substitution', 'instant replay', 'jump ball'];

export const GameContainer = ({ isFetchingGameStats, game, plays, players, teams }: IGameContainer) => {
    const [selectedTeam, setSelectedTeam] = React.useState(teams[0]);
    const [selectedPlayer, setSelectedPlayer] = React.useState(players.filter(player => player.teamName === game.homeTeam.teamTricode)[0]?.playerLastName);
    const [selectedCategory, setSelectedCategory] = React.useState('made shot');
    const categories = getDistinctKeys(plays, 'actionType').filter(item => DENIED_CATEGORIES.indexOf(item) === -1).sort();

    React.useEffect(() => {
        if (!isFetchingGameStats && players.length) {
            setSelectedTeam(teams[0]);
            setSelectedPlayer(players.filter(player => player.teamName === teams[0].teamTricode)[0]?.playerLastName);
            setSelectedCategory('made shot');
        }
    }, [teams, players, isFetchingGameStats]);

    const handleTeamChange = React.useCallback((
        event: ChangeEvent<HTMLSelectElement>,
    ) => {
        const newTeam = teams.find(team => team.teamName === event.target.value);
        if (newTeam) {
            setSelectedTeam(newTeam);
            setSelectedPlayer(players.filter(player => player.teamName === newTeam.teamTricode)[0].playerLastName);
        }
    }, [players, teams]);

    const handlePlayerChange = React.useCallback((
        event: ChangeEvent<HTMLSelectElement>,
    ) => {
        const player = players.find(player => player.playerName === event.target.value)
        if (player) {
            setSelectedPlayer(player.playerLastName);
        }
    }, [players]);

    const handleCategoryChange = React.useCallback((
        event: ChangeEvent<HTMLSelectElement>,
    ) => {
        setSelectedCategory(event.target.value);
    }, []);


    if (isFetchingGameStats) {
        return (
            <Stack spacing={10} style={{ marginLeft: "50%", marginTop: 50 }}>
                <CircularProgress />
            </Stack>
        );
    }

    if (!players.length) {
        return <EmptyState text={"No highlights yet. Check back once the game is over!"} />
    }

    return (
        <Stack direction='column' spacing={5} style={containerStyle}>
            <Stack direction='row' gap={2} sx={{ display: 'flex', flex: 1 }}>
                <Box sx={dropDownStyles}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Team
                        </InputLabel>
                        <NativeSelect
                            defaultValue={selectedTeam.teamName}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) => handleTeamChange(event)}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',
                            }}
                        >
                            <option value={teams[0].teamName}><TeamName team={teams[0]} /></option>
                            <option value={teams[1].teamName}><TeamName team={teams[1]} /></option>
                        </NativeSelect>
                    </FormControl>
                </Box>
                <Box sx={dropDownStyles}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Player
                        </InputLabel>
                        <NativeSelect
                            defaultValue={selectedPlayer}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) => handlePlayerChange(event)}
                        >
                            {players.filter(player => player.teamName === selectedTeam?.teamTricode).map(player => <option value={player.playerName}>{player.playerName}</option>)}

                        </NativeSelect>
                    </FormControl>
                </Box>
                <Box sx={dropDownStyles}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Category
                        </InputLabel>
                        <NativeSelect
                            defaultValue={selectedCategory}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) => handleCategoryChange(event)}
                        >
                            {categories.map(category => <option value={category}>{convertStringToTitleCase(category)}</option>)}
                        </NativeSelect>
                    </FormControl>
                </Box>
            </Stack>
            <Stack sx={{ marginTop: '20px !important' }} direction='column'>
                <HighLights plays={plays} player={selectedPlayer} gameId={game.gameId} selectedCategory={selectedCategory} />
            </Stack>
        </Stack >
    );
};

const dropDownStyles = {
    width: '100%'
};

const containerStyle = {
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px'
};