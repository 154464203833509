import { APP_INSIGHTS_KEY } from './constants';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const initTelemetryService = () => {
    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: APP_INSIGHTS_KEY,
            extensions: [reactPlugin],
            extensionConfig: {},
        },
    });
    if (appInsights) {
        appInsights.loadAppInsights();
    }
    return appInsights;
};

export class TelemetryService {
    private static instance: TelemetryService;
    private static appInsights: ApplicationInsights;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor(appInsights: ApplicationInsights) {
        TelemetryService.appInsights = appInsights;
    }

    public static init() {
        if (!TelemetryService.instance) {
            const appInsights = initTelemetryService();
            TelemetryService.instance = new TelemetryService(appInsights);
        }
    }

    public static log(eventName: string, properties?: any) {
        TelemetryService.appInsights.trackEvent({
            name: eventName,
            properties: { ...properties },
        });
    }
}
