import React from 'react';
import { Box, Stack, Typography, IconButton, CardMedia, CardActionArea } from "@mui/material";
import { Play } from "../interfaces/schemas";
import CancelIcon from '@mui/icons-material/Cancel';
import { getTimeRemainingFromPlay } from "../helpers/utils";
import { useDispatch } from 'react-redux'
import { highlightVideoAdded } from "../redux/features/highlight-video";
import { highlightRemoved } from "../redux/features/highlights";
export interface IHighlightClip {
    play: Play;
    showRemoveButton: boolean;
}

export const HighlightClip = ({ play, showRemoveButton }: IHighlightClip) => {
    const dispatch = useDispatch();

    const removePlay = React.useCallback(() => {
        dispatch(highlightRemoved(play));
    }, [dispatch, play]);

    const thumbnailUrl = `https://cdn.nba.com/headshots/nba/latest/260x190/${play.personId}.png`;
    return (
        <Box sx={clipContainerStyle}>
            {showRemoveButton && <IconButton sx={deleteButtonStyle} onClick={removePlay}>
                <CancelIcon color="error" />
            </IconButton>}
            <CardActionArea onClick={() => dispatch(highlightVideoAdded(play.videoAsset?.videoUrl))} >
                <Box sx={clipStyle}>
                    <Stack direction='column' alignItems='center'>
                        <CardMedia
                            component="img"
                            image={thumbnailUrl}
                            alt="play thumbnail"
                            sx={thumbnailStyle}
                        />
                        <Typography fontSize="1rem">{`${play.playerName}`}</Typography>
                        <Typography sx={playTextStyle}>{`${play.actionType} | ${getTimeRemainingFromPlay(play)}`}</Typography>
                    </Stack>
                </Box>
            </CardActionArea>
        </Box>

    );
}

const clipContainerStyle = {
    position: 'relative',
    width: '160px',
    height: '130px',
}

const clipStyle = {
    width: '160px',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: '5px',
    border: "1px solid gray",
}

const playTextStyle = {
    fontSize: '0.75rem',
    marginBottom: '5px'
}

const deleteButtonStyle = {
    position: 'absolute',
    top: -20,
    right: -20,
    zIndex: 5
}

const thumbnailStyle = {
    width: '78px',
    height: '57px',
    borderRadius: '5px',
    marginTop: '10px',
    marginBottom: '5px'
}