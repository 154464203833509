import React from 'react';
import { getGamesByDate } from '../data/apiUtils';
import { Game } from '../interfaces/schemas';

export const enum GAME_ERROR {
    NO_GAMES_FOUND = 'NO_GAMES_FOUND'
}
export interface IUseGames {
    // Date of games to fetch
    date: Date;

    // Number of days before the date to look back
    lookBack?: number;
}
export const useGames = ({ date, lookBack = 0 }: IUseGames) => {

    const [games, setGames] = React.useState<Game[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState<GAME_ERROR | undefined>(undefined);

    const getGames = React.useCallback(async () => {
        setIsLoading(true);
        const games = await getGamesWithLookBack(date, lookBack);
        setGames(games);
        setIsLoading(false);
    }, [date, lookBack]);

    React.useEffect(() => {
        getGames();
    }, [getGames]);

    return {
        games,
        isLoading,
        error
    }
};

const getGamesWithLookBack = async (date: Date, lookBack: number): Promise<Game[]> => {
    const allGames: Game[] = [];
    const games = await getGamesByDate(convertDateToISO(date));
    if (games) {
        allGames.push(...games)
    }
    return allGames;
}

const convertDateToISO = (date: Date): string => {
    const tokens = date.toLocaleDateString('en-GB').replaceAll('/', '-').split('-');
    return `${tokens[tokens.length - 1]}-${tokens[1]}-${tokens[0]}`;
};