import { Game, Play, VideoAsset, VideoMetadata } from '../interfaces/schemas';
import {
    GAME_API,
    SHOT_STACK_API_KEY_STAGE,
    SHOT_STACK_RENDER_API,
    SPORTS_SCOUT_PROXY,
} from '../constants';
import { TelemetryService } from '../telemetry-service';
import axios from 'axios';
import {
    getGamesFromHTML,
    getPlaysFromHTML,
    stringToHTML,
} from '../helpers/utils';

const videoCache: { [key: string]: VideoMetadata[] } = {};

export const getGamesByDate2 = async (
    date: string
): Promise<Game[] | undefined> => {
    try {
        const response = await axios.get(
            `${GAME_API}/api/games?gameDate=${date}`
        );
        const games = response.data?.scoreboard?.games as Game[];
        TelemetryService.log('get_games_by_date_success', {
            games: games.length,
            date,
        });
        return games;
    } catch (error) {
        TelemetryService.log('get_games_by_date_error', {
            error: JSON.stringify(error),
            date,
        });
    }
    return undefined;
};

export const getGamesByDate = async (
    date: string
): Promise<Game[] | undefined> => {
    try {
        const results = await axios.get(
            `${SPORTS_SCOUT_PROXY}/www.nba.com/games?date=${date}`
        );
        const html = results.data;
        const document = stringToHTML(html);
        const games = getGamesFromHTML(document);
        TelemetryService.log('get_games_by_date_success', {
            games: games.length,
            date,
        });
        return games;
    } catch (error) {
        TelemetryService.log('get_games_by_date_error', {
            error: JSON.stringify(error),
            date,
        });
    }
    return undefined;
};

export const getPlaysForGame = async (
    gameId: string
): Promise<Play[] | undefined> => {
    try {
        const results = await axios(
            `${SPORTS_SCOUT_PROXY}/www.nba.com/game/${gameId}/play-by-play?period=All`
        );
        const html = results.data;
        const document = stringToHTML(html);
        const plays = getPlaysFromHTML(document);
        if (plays?.length) {
            plays.forEach((play) => (play.gameId = gameId));
        }
        return plays;
    } catch (error) {
        TelemetryService.log('get_plays_for_game_error', {
            error: JSON.stringify(error),
            gameId,
        });
        return undefined;
    }
};

export const getVideoMetadataBatch = async (
    plays: Play[],
    gameId: string
): Promise<{ [key: string]: VideoAsset } | undefined> => {
    const promises: Promise<
        { videoMetadata: VideoMetadata[]; playActionNumber: number } | undefined
    >[] = [];
    plays.forEach((play) => {
        const promise = getVideoMetadata(play.actionNumber, gameId);
        promises.push(promise);
    });
    return Promise.all(promises)
        .then((values) => {
            const videoUrls: { [key: string]: VideoAsset } = {};
            if (values.length) {
                values.forEach((value) => {
                    if (
                        value?.videoMetadata?.length &&
                        value.videoMetadata[0]?.lurl
                    ) {
                        videoUrls[value.playActionNumber] = {
                            videoUrl: value.videoMetadata[0]?.lurl,
                            duration: value.videoMetadata[0]?.ldur,
                            thumbnailUrl: value.videoMetadata[0]?.lth,
                        };
                    }
                });
            }
            return videoUrls;
        })
        .catch((error) => {
            TelemetryService.log('get_video_metadata_batch_error', {
                error: JSON.stringify(error),
                playsLength: plays.length,
                gameId,
            });
            return undefined;
        });
};

export const getVideoMetadata = async (
    playActionNumber: number,
    gameId: string
): Promise<
    { videoMetadata: VideoMetadata[]; playActionNumber: number } | undefined
> => {
    try {
        const key = `${gameId}:${playActionNumber}`;
        if (videoCache[key]) {
            TelemetryService.log('get_video_metadata_cache_success', {
                videoMetadata: videoCache[key],
                gameId,
                playActionNumber,
            });
            return Promise.resolve({
                videoMetadata: videoCache[key],
                playActionNumber,
            });
        }
        const response = await axios.get(
            `${GAME_API}/api/video?gameEventId=${playActionNumber}&gameId=${gameId}`
        );
        const videoMetadata = response.data as VideoMetadata[];
        TelemetryService.log('get_video_metadata_success', {
            videoMetadata,
            gameId,
            playActionNumber,
        });
        videoCache[key] = videoMetadata;
        return { videoMetadata, playActionNumber };
    } catch (error) {
        TelemetryService.log('get_video_metadata_error', {
            error: JSON.stringify(error),
            gameId,
            playActionNumber,
        });
    }
    return undefined;
};

export const createHighlightVideo = async (
    assets: VideoAsset[]
): Promise<string> => {
    if (!assets?.length) {
        return Promise.resolve('');
    }

    let cursor = 0;
    const tracks: any = [];
    assets.forEach((asset) => {
        const clipLength = Math.floor(asset.duration / 1000);
        const track = {
            clips: [
                {
                    asset: {
                        type: 'video',
                        src: asset.videoUrl,
                    },
                    start: cursor,
                    length: clipLength,
                },
            ],
        };
        tracks.push(track);
        cursor += clipLength;
    });

    const requestBody = {
        timeline: {
            tracks: tracks,
        },
        output: {
            format: 'mp4',
            resolution: 'sd',
        },
    };

    try {
        const response = await axios.post(SHOT_STACK_RENDER_API, requestBody, {
            headers: { 'x-api-key': SHOT_STACK_API_KEY_STAGE },
        });
        if (!response?.data?.response?.id) {
            return '';
        }

        const responseId = response.data.response.id as string;

        return (await poll({
            fn: getMergedVideoUrl,
            requestId: responseId,
            validate: (url) => !!url,
            interval: getPollingInterval(tracks.length),
            maxAttempts: 5,
        })) as string;
    } catch (error) {
        return '';
    }
};

const getPollingInterval = (numTracks: number): number => {
    if (numTracks < 3) {
        return 5000;
    }
    if (numTracks < 5) {
        return 8000;
    }
    return 10000;
};

export const getMergedVideoUrl = async (requestId: string): Promise<string> => {
    try {
        const response = await axios.get(
            `${SHOT_STACK_RENDER_API}/${requestId}`,
            { headers: { 'x-api-key': SHOT_STACK_API_KEY_STAGE } }
        );
        return response.data.response.url;
    } catch (error) {
        return '';
    }
};

interface IPoll {
    fn: (requestId: string) => Promise<string>;
    requestId: string;
    validate: (url: string | undefined) => boolean;
    interval: number;
    maxAttempts: number;
}
const poll = async ({
    fn,
    requestId,
    validate,
    interval,
    maxAttempts,
}: IPoll) => {
    let attempts = 0;

    const executePoll = async (resolve: any, reject: any) => {
        const result = await fn(requestId);
        attempts++;

        if (validate(result)) {
            return resolve(result);
        } else if (maxAttempts && attempts === maxAttempts) {
            return reject(new Error('Exceeded max attempts'));
        } else {
            setTimeout(executePoll, interval, resolve, reject);
        }
    };

    return new Promise(executePoll);
};

// {
//     "timeline": {
//       "tracks": [
//         {
//           "clips": [
//             {
//               "asset": {
//                 "type": "video",
//                 "src": "https://videos.nba.com/nba/pbp/media/2022/10/14/0012200070/25/a0587898-4663-7c7a-75a5-2e17c6efedd9_1280x720.mp4"
//               },
//               "start": 0,
//               "length": 7
//             }
//           ]
//         },
//         {
//           "clips": [
//             {
//               "asset": {
//                 "type": "video",
//                 "src": "https://videos.nba.com/nba/pbp/media/2022/10/14/0012200070/37/2c50c5b6-57dd-47f2-582d-02509faa6a74_1280x720.mp4"
//               },
//               "start": 7,
//               "length": 10
//             }
//           ]
//         }
//       ]
//     },
//     "output": {
//       "format": "mp4",
//       "resolution": "sd"
//     }
//   }
