export const APP_INSIGHTS_KEY = 'a663e829-a3c9-47e3-80b7-d5edf42e3381';
// export const GAME_API = 'http://localhost:3080';
// export const GAME_API = 'https://sports-stats-service.azurewebsites.net';
export const GAME_API = 'https://8a61-198-244-110-72.ngrok.io';
export const SHOT_STACK_RENDER_API = 'https://api.shotstack.io/stage/render';
export const SHOT_STACK_API_KEY_STAGE =
    'Kowf4TSwOHxGPsM4WAF19Hb0DIkpVDN6XsK43YKc';
export const SPORTS_SCOUT_PROXY =
    'https://sports-scout-proxy.azurewebsites.net';
// const SPORTS_SCOUT_PROXY = 'http://localhost:5000';
