import { Box } from '@mui/material';
import { Game } from '../interfaces/schemas';
import { GameCard } from './game-card';

export interface IGameCarousel {
    games: Game[];
    onGameSelected: (game: Game) => void;
    selectedGame: Game | undefined;
    isFetchingGameStats: boolean;
}
export const GameCarousel = ({ games, onGameSelected, selectedGame, isFetchingGameStats }: IGameCarousel) => {
    return (
        <Box sx={carouselStyle}>
            {games.map(game => <GameCard key={game.gameId} game={game} onGameSelected={onGameSelected} isSelected={selectedGame?.gameId === game.gameId} isDisabled={isFetchingGameStats} />)}
        </Box>
    )
}

const carouselStyle = {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto'
}