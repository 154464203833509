import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
export const AppBar = () => {
    return (
        <Box sx={appBarStyle} position="static">
            <Typography sx={appTitleStyle} color="text.secondary" gutterBottom>
                {"NBA Reel"}
            </Typography>
        </Box>
    );
};

const appBarStyle = {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'black',
    height: '60px',
    width: '100%',
    alignItems: 'center',
    boxShadow: '2px 2px 5px black'
};

const appTitleStyle = {
    color: 'white',
    fontSize: 30,
    margin: 0
};
