import { Button, Stack } from "@mui/material"
import React from "react";
import { createHighlightVideo, getVideoMetadataBatch } from "../data/apiUtils";
import { Play, VideoAsset } from "../interfaces/schemas";
import { TelemetryService } from "../telemetry-service";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid, GridCallbackDetails, GridColumnHeaderParams, GridCellParams, GridColDef, GridSelectionModel, MuiEvent } from '@mui/x-data-grid';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { VideoContainer } from "./video-container";
import { useDispatch, useSelector } from 'react-redux'
import { highlightsAdded, selectHighlights, highlightsRemoved } from "../redux/features/highlights";
import { setHighlightClipsMetadata } from "../helpers/utils";
import { highlightVideoAdded } from "../redux/features/highlight-video";

export interface IHighlights {
    plays: Play[];
    player: string;
    gameId: string;
    selectedCategory: string;
}

export const HighLights = ({ plays, player, gameId, selectedCategory }: IHighlights) => {
    const [videoMetadata, setVideoMetadata] = React.useState<{ [key: string]: VideoAsset } | undefined>(undefined);
    const [isHighlighVideoLoading, setIsHighlightVideoLoading] = React.useState(false);
    const [highlightVideoUrl, setHighlightVideoUrl] = React.useState<string | undefined>(undefined);
    const [selectedVideoUrl, setSelectedVideoUrl] = React.useState<string | undefined>(undefined);

    const [isAllSelected, setIsAllSelected] = React.useState(false);
    const dispatch = useDispatch();
    const highlights = useSelector(selectHighlights);

    React.useEffect(() => {
        setIsAllSelected(false);
    }, [plays, player, gameId, selectedCategory]);


    const getVideoMetadata = React.useCallback(async () => {
        if (plays.length && selectedCategory) {
            const playsToGetVideosFor = plays.filter(play => play.playerName === player && play.videoAvailable && play.actionType.toLowerCase() === selectedCategory);
            const videoMetadata = await getVideoMetadataBatch(playsToGetVideosFor, gameId);
            if (videoMetadata) {
                setHighlightClipsMetadata(playsToGetVideosFor, videoMetadata);
            }
            setVideoMetadata(videoMetadata);
        }

    }, [plays, player, gameId, selectedCategory]);

    React.useEffect(() => {
        getVideoMetadata()
    }, [getVideoMetadata]);

    const onHighlightReelClick = React.useCallback(async () => {
        if (videoMetadata) {
            const videoAssets = getHighlightClipsMetadata(highlights, player, videoMetadata, selectedCategory);
            if (videoAssets?.length) {
                TelemetryService.log('highlight_clip_create_attempt', { player, gameId, videoAssets: JSON.stringify(videoAssets) });
                setIsHighlightVideoLoading(true);
                const videoUrl = await createHighlightVideo(videoAssets);
                if (videoUrl) {
                    setHighlightVideoUrl(videoUrl);
                    TelemetryService.log('highlight_clip_create_success', { player, gameId, videoAssets: JSON.stringify(videoAssets), videoUrl });
                }
                setIsHighlightVideoLoading(false);
            }
        }
    }, [player, highlights, selectedCategory, videoMetadata, gameId]);

    const onPlayReel = React.useCallback(async () => {
        if (highlightVideoUrl) {
            TelemetryService.log('highlight_clip_link_open_success', { player, gameId, videoUrl: highlightVideoUrl });
            window.open(highlightVideoUrl, '_blank');
        }
    }, [highlightVideoUrl, gameId, player]);

    const onColumnHeaderClick = React.useCallback((params: GridColumnHeaderParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        if (params.field === "__check__") {
            const newPlays = plays.filter(play => play.playerName === player && play.videoAvailable && play.actionType.toLowerCase() === selectedCategory);
            if (!isAllSelected) {
                dispatch(highlightsAdded(newPlays));
            } else {
                dispatch(highlightsRemoved(newPlays));
            }
            setIsAllSelected(!isAllSelected);
        }
    }, [isAllSelected, dispatch, plays, player, selectedCategory]);

    const onCellClick = React.useCallback((params: GridCellParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        if (params.field !== "__check__") {
            const { videoUrl, linkUrl } = getVideoUrl(params.row.play, gameId, videoMetadata);
            if (videoUrl) {
                setSelectedVideoUrl(videoUrl);
                dispatch(highlightVideoAdded(videoUrl));
                TelemetryService.log('highlight_selected', { videoUrl, highlightText: params.row.play.description, play: JSON.stringify(params.row.play), player, gameId, isDirectVideo: true, isLinkVideo: false });
            } else if (linkUrl) {
                window.open(linkUrl, '_blank');
                TelemetryService.log('highlight_selected', { videoUrl, highlightText: params.row.play.description, play: JSON.stringify(params.row.play), player, gameId, isLinkVideo: true, isDirectVideo: false });
            }
        } else if (params.field === "__check__") {
            const isSelected = !!params.value;
            const selectedPlay = params.row.play;
            if (!isSelected) {
                dispatch(highlightsAdded([selectedPlay]));
            } else {
                dispatch(highlightsRemoved([selectedPlay]));
            }
        }
    }, [gameId, videoMetadata, player, dispatch]);

    const columns: GridColDef[] = [
        { field: 'quarter', headerName: 'Quarter', minWidth: 50 },
        { field: 'timeRemaining', headerName: 'Time Remaining', minWidth: 150 },
        { field: 'playType', headerName: 'Play Type', minWidth: 150 },
        {
            field: 'category',
            headerName: 'Category',
            minWidth: 150
        },
        { field: 'distance', headerName: 'Distance', minWidth: 150 }
    ];

    const rows = plays.filter(play => play.playerName === player && play.videoAvailable && play.actionType.toLowerCase() === selectedCategory).map(play => {
        return { id: play.actionNumber, quarter: play.period, timeRemaining: play.clock.replaceAll('PT', '').replaceAll('S', '').split('M').join(':').split('.')[0], playType: play.subType, category: play.actionType, distance: `${play.shotDistance} FT`, play }
    });

    return (
        <Stack px={highlightContainerStyle} direction='column' gap={1}>
            <VideoContainer videoUrl={selectedVideoUrl} />
            <Stack px={highlightsStyle}>
                <div style={{ height: '100vh', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        hideFooter
                        onCellClick={onCellClick}
                        onColumnHeaderClick={onColumnHeaderClick}
                        autoHeight
                        disableSelectionOnClick
                        selectionModel={highlights.filter(highlight => highlight.gameId === gameId).map(play => play.actionNumber)}
                    />
                </div>
            </Stack>
        </Stack>

    )
}

const isHighlightCreateable = (plays: Play[], videoMetadata: { [key: string]: VideoAsset } | undefined): boolean => {
    const validPlays = plays.filter(play => play.videoAvailable && videoMetadata && videoMetadata[play.actionNumber] && videoMetadata[play.actionNumber].videoUrl);
    return validPlays.length > 0;
}

const getHighlightClipsMetadata = (plays: Play[], player: string, videoMetadata: { [key: string]: VideoAsset }, selectedCategory: string): VideoAsset[] => {
    const assets = plays.filter(play => play.playerName === player && play.videoAvailable && play.actionType.toLowerCase() === selectedCategory && videoMetadata[play.actionNumber]).map(play => ({
        videoUrl: videoMetadata[play.actionNumber].videoUrl,
        duration: videoMetadata[play.actionNumber].duration,
        thumbnailUrl: videoMetadata[play.actionNumber].thumbnailUrl
    }));
    return assets;
}
const getVideoUrl = (play: Play, gameId: string, videoMetadata: { [key: string]: VideoAsset } | undefined): { videoUrl: string | undefined, linkUrl: string | undefined } => {
    if (videoMetadata && videoMetadata[play.actionNumber]) {
        return { videoUrl: videoMetadata[play.actionNumber].videoUrl, linkUrl: undefined };
    }
    return { linkUrl: `https://www.nba.com/stats/events/?CFID=&CFPARAMS=&GameEventID=${play.actionNumber}&GameID=${gameId}&Season=2021-22&flag=1`, videoUrl: undefined };
}

const highlightsStyle = {
    marginTop: '10px',
    gap: "5px"
};

const highlightContainerStyle = {
    display: 'flex',
};