import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Game } from '../interfaces/schemas';
import { CardActionArea } from '@mui/material';
import { TelemetryService } from '../telemetry-service';

export interface IGameCard {
    game: Game;
    onGameSelected: (game: Game) => void;
    isSelected: boolean;
    isDisabled: boolean;
}

const enum TeamType {
    HOME = 'HOME',
    AWAY = 'AWAY'
};

export const GameCard = ({ game, onGameSelected, isSelected, isDisabled }: IGameCard) => {
    const gameSelected = React.useCallback((game: Game) => {
        TelemetryService.log('game_selected', { game: JSON.stringify(game) });
        onGameSelected(game);
    }, [onGameSelected]);

    return (
        <Card variant="outlined" square sx={getCardStyle(isSelected)}>
            <CardActionArea onClick={() => gameSelected(game)} disabled={isSelected || isDisabled}>
                <CardContent>
                    <Box>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {game.gameStatusText}
                        </Typography>
                    </Box>
                    <Box sx={teamScoreStyle}>
                        <Box sx={teamNameStyle}>
                            <img alt={`${game.homeTeam.teamName} logo`} src={`https://cdn.nba.com/logos/nba/${game.homeTeam.teamId}/primary/L/logo.svg`} width={20} height={20} />
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {game.homeTeam.teamName}
                            </Typography>
                        </Box>
                        <Box sx={scoreStyle}>
                            <Typography sx={{ fontSize: 14, fontWeight: isGameWinner(game, TeamType.HOME) ? 'bolder' : 'regular' }} color="text.secondary" gutterBottom>
                                {game.homeTeam.score}
                            </Typography>
                        </Box>

                    </Box>
                    <Box sx={teamScoreStyle}>
                        <Box sx={teamNameStyle}>
                            <img alt={`${game.awayTeam.teamName} logo`} src={`https://cdn.nba.com/logos/nba/${game.awayTeam.teamId}/primary/L/logo.svg`} width={20} height={20} />
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {game.awayTeam.teamName}
                            </Typography>
                        </Box>
                        <Box sx={scoreStyle}>
                            <Typography sx={{ fontSize: 14, fontWeight: isGameWinner(game, TeamType.AWAY) ? 'bolder' : 'regular' }} color="text.secondary" gutterBottom>
                                {game.awayTeam.score}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

const isGameWinner = (game: Game, teamType: TeamType): boolean => {
    if (teamType === TeamType.HOME) {
        return game.homeTeam.score > game.awayTeam.score;
    }
    return game.awayTeam.score > game.homeTeam.score;
}


const teamScoreStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
};
const teamNameStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px'
}

const scoreStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px'
}

const getCardStyle = (isSelected: boolean) => {
    if (!isSelected) {
        return cardStyle;
    }

    return { ...cardStyle, backgroundColor: 'rgba(25, 118, 210, 0.08)', border: 'transparent' };
}
const cardStyle = {
    overflow: 'unset',
    minWidth: 160,
    maxWidth: 160,
}